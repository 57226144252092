import axios from "axios";
import { encrypt, decrypt } from "./cipher";
import { KEY, IV } from "./constants";
import Cookie from "./helpers/cookie";
import { generateCodeVerifierAndChallenge } from "./helpers/generateCodeVerifierAndChallenge";
import { STATUS_TENANT } from "@ems/constants/account_registration_status";

export const fetchEnv = async () => {
  const isEnv = getEnv();
  isEnv && localStorage.removeItem("env");

  const res = await axios.get("/env");
  const env = res.data + "";
  localStorage.setItem("env", env);

  return getEnv();
};

export const fetchEnvBoms = async () => {
  const env = await fetchEnv();

  const tempEnv = {
    VUE_APP_DOMAIN_API_SERVER: env.VUE_APP_DOMAIN_BOMS_API_SERVER,
    VUE_APP_AUTHENTICATION_SERVICE: env.VUE_APP_AUTHENTICATION_SERVICE,
  };

  localStorage.setItem("env", encrypt(JSON.stringify(tempEnv), KEY, IV));

  return getEnv();
};

export const fetchEnvEms = async () => {
  const env = await fetchEnv();

  const tempEnv = {
    VUE_APP_DOMAIN_API_SERVER: env.VUE_APP_DOMAIN_API_SERVER,
    VUE_APP_AUTHENTICATION_SERVICE: env.VUE_APP_AUTHENTICATION_SERVICE,
    VUE_APP_IDENTITY_URL: env.VUE_APP_IDENTITY_URL,
    VUE_APP_IDENTITY_CLIENT_ID: env.VUE_APP_IDENTITY_CLIENT_ID,
    VUE_APP_RESUITE_URL: env.VUE_APP_RESUITE_URL,
    VUE_APP_IDENTITY_REDIRECT_URL: env.VUE_APP_IDENTITY_REDIRECT_URL,
    VUE_APP_REDEX_ACCESS_TOKEN: env.VUE_APP_REDEX_ACCESS_TOKEN,
    VUE_APP_REDEX_REFRESH_TOKEN: env.VUE_APP_REDEX_REFRESH_TOKEN,
    VUE_APP_REDEX_ID_TOKEN: env.VUE_APP_REDEX_ID_TOKEN,
    VUE_APP_RESUITE_CODE_VERIFIER: env.VUE_APP_RESUITE_CODE_VERIFIER,
    VUE_APP_RESUITE_TENANT_ID: env.VUE_APP_RESUITE_TENANT_ID,
  };

  localStorage.setItem("env", encrypt(JSON.stringify(tempEnv), KEY, IV));

  return getEnv();
};

export const getEnv = () => {
  const envString = localStorage.getItem("env");
  // check envString not empty and not JSON
  if (!envString) {
    return {};
  }

  try {
    const decryptedString = decrypt(envString, KEY, IV).trim();
    return JSON.parse(decryptedString);
  } catch {
    return {};
  }
};

export const getLoginUrl = async (redirect?: string) => {
  const env = getEnv();
  const identityUrl = env?.VUE_APP_IDENTITY_URL;
  const clientId = env?.VUE_APP_IDENTITY_CLIENT_ID;
  const redirectUrl = encodeURIComponent(env?.VUE_APP_IDENTITY_REDIRECT_URL);
  const { codeVerifier, codeChallenge } =
    await generateCodeVerifierAndChallenge();

  setCodeVerifier(codeVerifier);
  const responseType = "code";
  const codeChallengeMethod = "S256";
  const scope = "REDEX_api openid profile email offline_access";
  const state = redirect
    ? encodeURIComponent(`redirect=${redirect}`)
    : undefined;
  const url = `${identityUrl}/connect/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scope}&code_challenge_method=${codeChallengeMethod}&code_challenge=${codeChallenge}${
    state ? `&state=${state}` : ""
  }`;
  return url;
};

export const getLogoutIdentityUrl = () => {
  const env = getEnv();
  const logoutRedirectUrl = encodeURIComponent(env?.VUE_APP_RESUITE_URL);
  const identityUrl = env?.VUE_APP_IDENTITY_URL;
  const idToken = getIdToken();
  const logoutUrl = `${identityUrl}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${logoutRedirectUrl}`;
  return logoutUrl;
};

export const logoutIdentity = () => {
  if (getIdToken()) {
    const logoutUrl = getLogoutIdentityUrl();
    clearAuthStorage();
    window.location.href = logoutUrl;
  } else {
    window.location.href = "/";
  }
};

export const setIdToken = (idToken: string) => {
  const env = getEnv();
  Cookie.setCookie(env?.VUE_APP_REDEX_ID_TOKEN, idToken);
};

export const getIdToken = () => {
  const env = getEnv();
  return Cookie.getCookie(env?.VUE_APP_REDEX_ID_TOKEN);
};

export const setAccessToken = (token: string) => {
  window.name = token;
  const env = getEnv();
  const accessToken = token;
  Cookie.setCookie(env?.VUE_APP_REDEX_ACCESS_TOKEN, accessToken);
};

export const getAccessToken = () => {
  const env = getEnv();
  return env ? Cookie.getCookie(env?.VUE_APP_REDEX_ACCESS_TOKEN) : null;
};

export const setRefreshToken = (token: string) => {
  const env = getEnv();
  Cookie.setCookie(env?.VUE_APP_REDEX_REFRESH_TOKEN, token);
};

export const getRefreshToken = () => {
  const env = getEnv();
  return Cookie.getCookie(env?.VUE_APP_REDEX_REFRESH_TOKEN);
};

export const setCodeVerifier = (codeVerifier: string) => {
  const env = getEnv();
  Cookie.setCookie(env?.VUE_APP_RESUITE_CODE_VERIFIER, codeVerifier);
};

export const getCodeVerifier = () => {
  const env = getEnv();
  return Cookie.getCookie(env?.VUE_APP_RESUITE_CODE_VERIFIER);
};

export const setTenantId = (tenantId: string) => {
  const env = getEnv();
  Cookie.setCookie(env?.VUE_APP_RESUITE_TENANT_ID, tenantId);
};

export const getTenantId = () => {
  const env = getEnv();
  return Cookie.getCookie(env?.VUE_APP_RESUITE_TENANT_ID);
};

export const setTenantStatus = (tenantStatus: STATUS_TENANT) => {
  localStorage.setItem("TenantStatus", tenantStatus.toString());
};

export const getTenantStatus = () => {
  return localStorage.getItem("TenantStatus");
};

export const setTenantIsActive = (isActive: boolean) => {
  localStorage.setItem("IsActive", isActive.toString());
};

export const getTenantIsActive = () => {
  return localStorage.getItem("IsActive");
};

export const setRole = (role: string) => {
  localStorage.setItem("Role", role);
};

export const getRole = () => {
  return localStorage.getItem("Role");
};

export const clearAuthStorage = () => {
  const env = getEnv();
  Cookie.eraseCookie(env.VUE_APP_REDEX_ACCESS_TOKEN);
  Cookie.eraseCookie(env.VUE_APP_REDEX_REFRESH_TOKEN);
  Cookie.eraseCookie(env.VUE_APP_REDEX_ID_TOKEN);
  Cookie.eraseCookie(env.VUE_APP_RESUITE_CODE_VERIFIER);
  Cookie.eraseCookie(env.VUE_APP_RESUITE_TENANT_ID);

  localStorage.removeItem("TenantStatus");
  localStorage.removeItem("IsActive");
  localStorage.removeItem("Role");
  localStorage.removeItem("lastVisitedPath");
};
