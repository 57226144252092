const sha256 = async (plain: string): Promise<ArrayBuffer> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  const hashed = await window.crypto.subtle.digest("SHA-256", data);
  return hashed;
};

const base64urlencode = (a: ArrayBuffer): string => {
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;
  let str = "";
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

const challenge_from_verifier = async (v: string): Promise<string> => {
  const hashed = await sha256(v);
  const base64encoded = base64urlencode(hashed);
  return base64encoded;
};

export const generateCodeVerifierAndChallenge = async (): Promise<{
  codeVerifier: string;
  codeChallenge: string;
}> => {
  const array = new Uint32Array(6);
  window.crypto.getRandomValues(array);
  const verifier = array.join("");
  const challenge = await challenge_from_verifier(verifier);
  return { codeVerifier: verifier, codeChallenge: challenge };
};
