import queryString from "query-string";
import AccountModule from "@/store/modules/Account";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "./axios/instance";
import { IResponse } from "./axios/entities";
import { getAccessToken, getEnv, logoutIdentity } from "@/utils/storage";
import {
  AuthorizationCodeRequest,
  ChangePasswordRequest,
  RefreshTokenRequest,
} from "@/models/IdentityServer";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { HTTP_STATUS_CODE } from "@ems/constants/http_status_code";

export class IdentityAPI {
  protected readonly instance: AxiosInstance;
  protected readonly token?: string;
  protected readonly tenantId?: string;
  constructor() {
    const env = getEnv();
    this.instance = axios.create({
      baseURL: env?.VUE_APP_IDENTITY_URL,
      headers: {
        "content-type": "application/json",
      },
      paramsSerializer: (params) => queryString.stringify(params),
    });
    this.requestInterceptor();
    this.responseInterceptor();
  }

  private requestInterceptor = () => {
    this.instance.interceptors.request.use((config: AxiosRequestConfig) => {
      const session = `Bearer ${getAccessToken()}`;
      const env = getEnv();
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      (config.headers as any).Authorization = `${session}`;
      (config.headers as any)["Accept-Timezone"] = tz;
      (config.headers as any)["X-Client-Id"] = env?.VUE_APP_IDENTITY_CLIENT_ID;
      return config;
    });
  };

  private responseInterceptor = () => {
    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        return {
          data: response.data,
        };
      },
      (error: AxiosError) => {
        const isChangePassword = error.config.url?.includes("change-password");
        if (
          !isChangePassword &&
          error.response?.status === HTTP_STATUS_CODE.BAD_REQUEST
        ) {
          logoutIdentity();
        }
        throw error;
      }
    );
  };

  public refreshToken = async (
    req: RefreshTokenRequest
  ): Promise<IResponse> => {
    const params = new URLSearchParams();
    params.append("grant_type", req.grantType);
    params.append("client_id", req.clientId);
    params.append("refresh_token", req.refreshToken);
    const resp: IResponse = await this.instance
      .post("/connect/token", params, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .catch(catchAxiosError);
    return resp;
  };

  public authorizationCode = async (
    req: AuthorizationCodeRequest
  ): Promise<IResponse> => {
    const params = new URLSearchParams();
    params.append("grant_type", req.grantType);
    params.append("code", req.code);
    params.append("client_id", req.clientId);
    params.append("redirect_uri", req.redirectUrl);
    params.append("code_verifier", req.codeVerifier);

    const resp: IResponse = await this.instance
      .post("/connect/token", params, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .catch(catchAxiosError);
    return resp;
  };

  public changeNewPassword = async (
    req: ChangePasswordRequest
  ): Promise<IResponse> => {
    const resp: IResponse = await this.instance
      .post("/api/auth/change-password", req)
      .catch(catchAxiosError);
    return resp;
  };
}
