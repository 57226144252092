import store from "@/store";
import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";

const name = "DialogModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class DialogModule extends VuexModule {
  private toggle = false;
  private component = null;
  private data = {};
  private rest = {};

  get isToggle() {
    return this.toggle;
  }

  get getModal() {
    return this.component;
  }

  get getData() {
    return this.data;
  }

  get getRest() {
    return this.rest;
  }

  @Mutation
  showModal(payload: { component: any; data?: any; [x: string]: any }) {
    const { component, data = null, ...rest } = payload;

    this.toggle = true;
    this.component = component;
    this.data = data;
    this.rest = rest;
  }

  @Mutation
  hideModal() {
    this.toggle = false;
    this.component = null;
    this.rest = {};
    this.data = {};
  }
}

export default getModule(DialogModule);
