const domain =
  process.env.NODE_ENV === "development" ? "localhost" : ".redex.eco";

const setCookie = (name: string, value: string) => {
  document.cookie = `${name}=${
    value || ""
  }; Path=/; Domain=${domain}; SameSite=Lax; Secure;`;
};

const setCookieWithCustomProperties = (
  name: string,
  value: string,
  customDomain: string,
  expr?: string
) => {
  document.cookie = `${name}=${
    value || ""
  }; Path=/; Domain=${customDomain}; SameSite=Lax; Secure; ${
    expr ? `Expires=${expr}` : ""
  }`;
};

const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const eraseCookie = (name: string) => {
  const COOKIE_NEVER_EXPIRES_TIME = "Thu, 01 Jan 1970 00:00:01 GMT";
  document.cookie = `${name}=; Path=/; Domain=${domain}; Expires=${COOKIE_NEVER_EXPIRES_TIME}; SameSite=None; Secure;`;
};

const Cookie = {
  setCookie,
  getCookie,
  eraseCookie,
  setCookieWithCustomProperties,
};

export default Cookie;
