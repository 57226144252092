import CryptoJS from "crypto-js";

export const encrypt = (
  jsonString: string,
  keyString: string,
  ivString: string
) => {
  const iv = CryptoJS.enc.Utf8.parse(ivString);

  const encrypted = CryptoJS.AES.encrypt(jsonString, keyString, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

export const decrypt = (
  cipherText: string,
  keyString: string,
  ivString: string
) => {
  const iv = CryptoJS.enc.Utf8.parse(ivString);

  const decrypted = CryptoJS.AES.decrypt(cipherText, keyString, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
