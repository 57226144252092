import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import simpleBrowserFingerprint from "simple-browser-fingerprint";

import LocalStorage from "./LocalStorage";

const debug = process.env.NODE_ENV === "development";

export class SecureStorage extends SecureLS {
  protected ls: Storage;

  constructor(config: any) {
    super(config);

    this.ls = config.useSessionStore ? sessionStorage : localStorage;
  }
}

const ls = new SecureStorage(
  debug
    ? {
        encodingType: "",
        isCompression: false,
        useSessionStore: true,
        encryptionSecret: undefined,
      }
    : {
        encodingType: "rc4",
        isCompression: true,
        useSessionStore: true,
        encryptionSecret: simpleBrowserFingerprint(),
      }
);

const persistedLocalStorage = createPersistedState({
  paths: ["LocalStorage"],
  storage: {
    getItem: (key) => {
      try {
        return ls.get(key);
      } catch (error) {
        ls.remove(key);
        return ls.get(key);
      }
    },
    setItem: (key, value) => {
      ls.set(key, value);
    },
    removeItem: (key) => {
      ls.remove(key);
    },
  },
});

const store = new Vuex.Store({
  modules: {
    LocalStorage,
  },
  strict: debug,
  plugins: [persistedLocalStorage],
});

export default store;
