import { ElDialog } from "element-plus/lib/components/dialog";
import { ElForm, ElFormItem } from "element-plus/lib/components/form";
import { ElInput } from "element-plus/lib/components/input";
import { ElInputNumber } from "element-plus/lib/components/input-number";
import { ElRadio } from "element-plus/lib/components/radio";
import { ElDatePicker } from "element-plus/lib/components/date-picker";
import { ElSelect } from "element-plus/lib/components/select";
import { ElTable } from "element-plus/lib/components/table";
import { ElUpload } from "element-plus/lib/components/upload";
import { ElSwitch } from "element-plus/lib/components/switch";
import { ElTabs } from "element-plus/lib/components/tabs";
import { ElProgress } from "element-plus/lib/components/progress";
import { ElTooltip } from "element-plus/lib/components/tooltip";
import { ElPagination } from "element-plus/lib/components/pagination";
import { ElLoading } from "element-plus/lib/components/loading";
import { ElCheckbox } from "element-plus/lib/components/checkbox";
import { ElDropdown } from "element-plus/lib/components/dropdown";
import { ElPopover } from "element-plus/lib/components/popover";

import "element-plus/lib/components/dialog/style/css";
import "element-plus/lib/components/form/style/css";
import "element-plus/lib/components/form-item/style/css";
import "element-plus/lib/components/input/style/css";
import "element-plus/lib/components/input-number/style/css";
import "element-plus/lib/components/radio/style/css";
import "element-plus/lib/components/date-picker/style/css";
import "element-plus/lib/components/select/style/css";
import "element-plus/lib/components/table/style/css";
import "element-plus/lib/components/upload/style/css";
import "element-plus/lib/components/switch/style/css";
import "element-plus/lib/components/tabs/style/css";
import "element-plus/lib/components/progress/style/css";
import "element-plus/lib/components/tooltip/style/css";
import "element-plus/lib/components/pagination/style/css";
import "element-plus/lib/components/loading/style/css";
import "element-plus/lib/components/checkbox/style/css";

import "element-plus/lib/components/message/style/css";
import "element-plus/lib/components/dropdown/style/css";

export default (app: unknown): void => {
  (app as any).use(ElDialog);
  (app as any).use(ElForm);
  (app as any).use(ElFormItem);
  (app as any).use(ElInput);
  (app as any).use(ElInputNumber);
  (app as any).use(ElRadio);
  (app as any).use(ElDatePicker);
  (app as any).use(ElSelect);
  (app as any).use(ElTable);
  (app as any).use(ElUpload);
  (app as any).use(ElSwitch);
  (app as any).use(ElTabs);
  (app as any).use(ElProgress);
  (app as any).use(ElTooltip);
  (app as any).use(ElPagination);
  (app as any).use(ElLoading);
  (app as any).use(ElCheckbox);
  (app as any).use(ElDropdown);
  (app as any).use(ElPopover);
};
